<template>
  <header class="flex justify-between border-b-2 items-baseline p-3 sm:px-6">
    <div class="w-1/2">
      <slot></slot>
    </div>
    <div class="w-1/2 text-right" >
      <slot name="actions"></slot>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style></style>
