<template>
  <h3 class="text-lg font-medium leading-6 text-gray-900">
    <slot></slot>
  </h3>
</template>

<script>
export default {};
</script>

<style></style>
