<template>
  <app-layout>
    <template v-slot:header> Comanda # {{ orderId }} </template>
    <page-section>
      <page-section-body class="flex space-x-4 overflow-x-auto">
        <v-card class="w-1/3">
          <v-card-body>
            <v-card-body-title> Subtotal </v-card-body-title>
            <p class="text-lg font-bold">{{ formatPrice(order.subtotal) }}</p>
          </v-card-body>
        </v-card>
        <v-card class="w-1/3">
          <v-card-body>
            <v-card-body-title> Transport </v-card-body-title>
            <p class="text-lg font-bold">{{ formatPrice(order.shipping) }}</p>
          </v-card-body>
        </v-card>
        <v-card class="w-1/3">
          <v-card-body>
            <v-card-body-title> Total </v-card-body-title>
            <p class="text-lg font-bold">{{ formatPrice(order.total) }}</p>
          </v-card-body>
        </v-card>
      </page-section-body>
    </page-section>

    <page-section>
      <page-section-body>
        <v-card>
          <v-card-header>
            <v-card-title>Comenzi vanzatori</v-card-title>

            <template v-slot:actions>
              <modal-update-order-status
                @update:order="show"
                :order-id="orderId"
              />
            </template>
          </v-card-header>
          <loader v-if="loading" class="my-5" />
          <template v-else>
            <template v-if="totalRows">
              <orders-show-table :rows="rows" />
              <pagination
                @change:page="index($event)"
                :total="meta.total"
                :current_page="meta.current_page"
                :from="meta.from"
                :to="meta.to"
                :next_page_url="links.next"
                :prev_page_url="links.prev"
              />
            </template>
            <no-results v-else class="m-5" />
          </template>
        </v-card>
      </page-section-body>
    </page-section>
  </app-layout>
</template>

<script>
import ordersApi from "../api/resources/ordersApi";

import VCard from "../components/ui/VCard.vue";
import AppLayout from "../layout/AppLayout.vue";

import Loader from "../components/Loader.vue";
import Pagination from "../components/Pagination.vue";
import NoResults from "../components/NoResults.vue";
import { priceFormatter } from "@/helpers";
import OrdersShowTable from "../components/OrdersShowTable.vue";
import VCardHeader from "../components/ui/VCardHeader.vue";
import VCardTitle from "../components/ui/VCardTitle.vue";
import PageSection from "../components/ui/PageSection.vue";
import PageSectionBody from "../components/ui/PageSectionBody.vue";
import VCardBody from "../components/ui/VCardBody.vue";
import VCardBodyTitle from "../components/ui/VCardBodyTitle.vue";
import ModalUpdateOrderStatus from "../components/ModalUpdateOrderStatus.vue";

export default {
  components: {
    AppLayout,
    VCard,
    Loader,
    Pagination,
    NoResults,
    OrdersShowTable,
    VCardHeader,
    VCardTitle,
    PageSection,
    PageSectionBody,
    VCardBody,
    VCardBodyTitle,
    ModalUpdateOrderStatus,
  },

  props: ["orderId"],

  data() {
    return {
      order: {},
      rows: [],
      meta: {},
      links: {},
      loading: true,
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },

    orderTotal() {
      return this.order.total ? priceFormatter(this.order.total) : "N/A";
    },
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    show() {
      this.loading = true;
      ordersApi
        .show(this.orderId)
        .then((response) => {
          this.order = response.data;
          this.loading = false;
          this.index();
        })
        .catch((error) => console.log(error));
    },

    index(page = null) {
      this.loading = true;
      ordersApi
        .vendorOrdersIndex(this.orderId, page && { page })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;
          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.show();
    // this.index();
  },
};
</script>

<style></style>
